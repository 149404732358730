import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui/ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import {
    LOGOUT,
    OPEN_POPUP,
    SET_MENU,
    SET_USER_PROFILE,
    SNACKBAR_OPEN,
    UPDATE_PAYSTACK_SETTINGS,
    UPDATE_SETTINGS,
    UPDATE_WALLET_BALANCE
} from 'store/actions';

// assets
import { IconChevronRight } from '@tabler/icons';

import useNetworkStatus from 'hooks/useNetworkStatus';
import Customization from 'layout/Customization';
import axios from 'axios';
import handleAxiosError from 'utils/handleAxiosErrors';
import { axiosAuthenticated, setLogoutCallback } from 'utils/axios';
import useAutoLogout from 'hooks/useAutoLogout';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // Calculate token expiration timestamp (30 minutes = 1800 seconds)
    const tokenExpirationTime = 1800 * 1000; // 1800 seconds converted to milliseconds
    const tokenExpirationTimestamp = Date.now() + tokenExpirationTime;

    const handleLogout = async () => {
        try {
            await axios.get(`/method/logout`);
            dispatch({
                type: LOGOUT
            });
            sessionStorage.clear();
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'Your session has expired. Please log in again.',
                variant: 'alert',
                alertSeverity: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    // Use the custom hook
    useAutoLogout(tokenExpirationTimestamp, handleLogout);

    // network online and offline status
    useNetworkStatus();

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        // Set up the logout callback
        const handleLogout = () => {
            // Perform your logout logic here, e.g., clear user session, navigate to logout page, etc.
            dispatch({ type: LOGOUT });
            sessionStorage.clear();
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'Your session has expired. Please log in again.',
                variant: 'alert',
                alertSeverity: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        };

        // Pass the logout callback to the interceptor
        setLogoutCallback(handleLogout);

        // Clean up by resetting the logout callback when the component unmounts
        return () => {
            setLogoutCallback(null);
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    useEffect(() => {
        function getData() {
            const userId = sessionStorage.getItem('userId');

            const request0 = axios.get(`/method/hot_recharge_app.hot_recharge_app.hr_api_object.hr_app_settings`);
            const request1 = axios.get(`/method/vps_retail.vps_api.vps_app_settings`);
            const request2 = axios.get(`/method/ott_scode.api.os_app_settings`);

            const request3 = axios.get(
                `/resource/SimplexWallet?fields=["*"]&filters=[["SimplexWallet","owner","=","${userId}"]]`
            );
            const request4 = axios.get(`/resource/Note?fields=["*"]&order_by=creation desc`);

            const request5 = axiosAuthenticated.get(`/method/simplex_billpay.simplex_billpay.api.get_billpay_configs`);
            const request6 = axiosAuthenticated.get(`/method/simplex_auth_app.simplex_auth_app.api.get_gateway_config`);

            // Fetch the user profile with limited fields
            const request7 = axiosAuthenticated.get(`/resource/User`, {
                params: {
                    fields: JSON.stringify(["email", "first_name", "last_name", "mobile_no", "phone"]),
                    filters: JSON.stringify([["name", "=", userId]])
                },
                withCredentials: true
            });


            axios
                .all([request0, request1, request2, request3, request4, request5, request6, request7])

                .then(
                    axios.spread((...res) => {
                        dispatch({
                            type: UPDATE_SETTINGS,
                            // HOT RECHARGE APP SETTINGS
                            zesaRate: res[0].data.message.zesa_rate,
                            zesaUsdRate: res[0].data.message.zesa_usd_rate,
                            conversionRate: res[0].data.message.conversion_rate,
                            dataRate: res[0].data.message.data_rate,
                            zigRate: res[0].data.message.zig_rate,
                            walletRate: res[0].data.message.wallet_rate,
                            walletUSDRate: res[0].data.message.wallet_rate_usd,
                            zesaMinimumLimit: res[0].data.message.zesa_limit,
                            airtimeMinimumLimit: res[0].data.message.airtime_limit,
                            netoneBundleMinimumLimit: res[0].data.message.netone_bundle,
                            econetBundleRate: res[0].data.message.econet_bundle_rate,
                            oneFiBundleRate: res[0].data.message.onefi_bundle,
                            netoneBundleRate: res[0].data.message.netone_bundle_rate,
                            serviceRate: res[0].data.message.service_rate,
                            walletRateUSD: res[0].data.message.usd_bundle_rate,
                            nyaradzoRate: res[0].data.message.nyaradzo_rate,
                            telOneRate: res[0].data.message.telone_bundle_rate,

                            // VPS App Service Fees
                            dstvServiceFee: res[1].data.message.dstv_service_fee,
                            busServiceFee: res[1].data.message.bus_service_fee,
                            dummyFBEAmount: res[1].data.message.dummy_fbe_amount, // Dummy FBE Amount

                            // OTT Voucher Service Fess
                            ottRedeemFee: res[2].data.message.ott_redeem_fee,
                            ottDepositFee: res[2].data.message.ott_deposit_fee,

                            // SCode Service Fee
                            scodeFee: res[2].data.message.scode_fee,

                            // Paynow BillPay Settings
                            defaultBillPayZiGConvert: parseFloat(res[5].data.message.zwl_convert),
                            defaultBillPayUSDConvert: parseFloat(res[5].data.message.usd_convert),
                            billPayDepartmentRatesTable: res[5].data.message.department_rates_table
                        });

                        // UPDATE WALLET BALANCE
                        if (res[3].data.data.length > 0) {
                            const walletZARBalance = parseFloat(res[3].data.data[0].balance);
                            const walletZIMBalance = parseFloat(res[3].data.data[0].zwl_balance);
                            const walletUSDBalance = parseFloat(res[3].data.data[0].usd_balance);

                            dispatch({
                                type: UPDATE_WALLET_BALANCE,
                                walletZARBalance,
                                walletZIMBalance,
                                walletUSDBalance
                            });
                        }

                        // SHOW POP UP DIALOG ONLY ONCE WHEN USER LOGS IN
                        if (res[4].data.data.length > 0) {
                            const popupSeen = Boolean(sessionStorage.getItem('popup-seen'));
                            if (!popupSeen)
                                dispatch({
                                    type: OPEN_POPUP,
                                    open: true,
                                    title: res[4].data.data[0].title,
                                    content: res[4].data.data[0].content,
                                    expires: res[4].data.data[0].expire_notification_on,
                                    public: res[4].data.data[0].public
                                });
                        }

                        // UPDATE PAYSTACK SETTINGS 
                        dispatch({
                            type: UPDATE_PAYSTACK_SETTINGS,
                            paystackDepositRate: res[6].data.message.fee,
                            paystackMinimumDeposit: res[6].data.message.min_deposit,
                            paystackTransactionLimit: res[6].data.message.max_limit
                        });

                        // Get the user profile
                        const userProfile = res[7].data.data[0]; // Access the first item in the data array

                        // Update the phone number state with the one that has a value
                        const phone_number = userProfile.mobile_no || userProfile.phone || '';
            
                        // Dispatch the action to store the user profile in Redux
                        dispatch({
                            type: SET_USER_PROFILE,
                            payload: {
                                ...userProfile,
                                phone_number
                            }
                        });
                    })
                )

                .catch((error) => {
                    const errorMsg = handleAxiosError(error);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: errorMsg,
                        variant: 'alert',
                        alertSeverity: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                });
        }
        if (sessionStorage.getItem('userId') != null) {
            getData();
        }
    }, [dispatch]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
            {/* <Customization /> */}
        </Box>
    );
};

export default MainLayout;
