// action - state management
import * as actionTypes from './actions';

const initialState = {
    zesaRate: 0,
    zesaUsdRate: 0,
    zigRate: 0,
    conversionRate: 0,
    dataRate: 0,
    walletRate: 0,
    walletUSDRate: 0,
    zesaMinimumLimit: 0,
    airtimeMinimumLimit: 0,
    netoneBundleMinimumLimit: 0,
    econetBundleRate: 0,
    oneFiBundleRate: 0,
    netoneBundleRate: 0,
    serviceRate: 0,
    walletRateUSD: 0,
    dstvServiceFee: 0,
    busServiceFee: 0,
    dummyFBEAmount: 0,
    ottRedeemFee: 0,
    ottDepositFee: 0,
    scodeFee: 0,
    nyaradzoRate: 0,
    telOneRate: 0,
    billPayZiGConvert: 0,
    billPayUSDConvert: 0,
    defaultBillPayZiGConvert: 0,
    defaultBillPayUSDConvert: 0,
    paystackDepositRate: 0,
    paystackMinimumDeposit: 0,
    paystackTransactionLimit: 0,
    billPayDepartmentRatesTable: []
};

// ==============================|| SETTINGS REDUCER ||============================== //

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SETTINGS:
            return {
                ...state,
                zigRate: action.zigRate ? action.zigRate : initialState.zigRate,
                zesaUsdRate: action.zesaUsdRate ? action.zesaUsdRate : initialState.zesaUsdRate,
                zesaRate: action.zesaRate ? action.zesaRate : initialState.zesaRate,
                serviceRate: action.serviceRate ? action.serviceRate : initialState.serviceRate,
                walletRateUSD: action.walletRateUSD ? action.walletRateUSD : initialState.walletRateUSD,
                walletUSDRate: action.walletUSDRate ? action.walletUSDRate : initialState.walletUSDRate,
                conversionRate: action.conversionRate ? action.conversionRate : initialState.conversionRate,
                dataRate: action.dataRate ? action.dataRate : initialState.dataRate,
                walletRate: action.walletRate ? action.walletRate : initialState.walletRate,
                zesaMinimumLimit: action.zesaMinimumLimit ? action.zesaMinimumLimit : initialState.zesaMinimumLimit,
                airtimeMinimumLimit: action.airtimeMinimumLimit ? action.airtimeMinimumLimit : initialState.airtimeMinimumLimit,
                netoneBundleMinimumLimit: action.netoneBundleMinimumLimit
                    ? action.netoneBundleMinimumLimit
                    : initialState.netoneBundleMinimumLimit,
                econetBundleRate: action.econetBundleRate ? action.econetBundleRate : initialState.econetBundleRate,
                oneFiBundleRate: action.oneFiBundleRate ? action.oneFiBundleRate : initialState.oneFiBundleRate,
                netoneBundleRate: action.netoneBundleRate ? action.netoneBundleRate : initialState.netoneBundleRate,
                dstvServiceFee: action.dstvServiceFee ? action.dstvServiceFee : initialState.dstvServiceFee,
                busServiceFee: action.busServiceFee ? action.busServiceFee : initialState.busServiceFee,
                dummyFBEAmount: action.dummyFBEAmount ? action.dummyFBEAmount : initialState.dummyFBEAmount,
                ottRedeemFee: action.ottRedeemFee ? action.ottRedeemFee : initialState.ottRedeemFee,
                ottDepositFee: action.ottDepositFee ? action.ottDepositFee : initialState.ottDepositFee,
                scodeFee: action.scodeFee ? action.scodeFee : initialState.scodeFee,
                nyaradzoRate: action.nyaradzoRate ? action.nyaradzoRate : initialState.nyaradzoRate,
                telOneRate: action.telOneRate ? action.telOneRate : initialState.telOneRate,
                defaultBillPayZiGConvert: action.defaultBillPayZiGConvert
                    ? action.defaultBillPayZiGConvert
                    : initialState.defaultBillPayZiGConvert,
                defaultBillPayUSDConvert: action.defaultBillPayUSDConvert
                    ? action.defaultBillPayUSDConvert
                    : initialState.defaultBillPayUSDConvert,
                billPayDepartmentRatesTable: action.billPayDepartmentRatesTable
                    ? action.billPayDepartmentRatesTable
                    : initialState.billPayDepartmentRatesTable
            };
        case actionTypes.PATCH_BILL_PAY_RATES:
            return {
                ...state,
                billPayZiGConvert: action.billPayZiGConvert ? action.billPayZiGConvert : initialState.billPayZiGConvert,
                billPayUSDConvert: action.billPayUSDConvert ? action.billPayUSDConvert : initialState.billPayUSDConvert
            };

        case actionTypes.UPDATE_PAYSTACK_SETTINGS:
            return {
                ...state,
                paystackDepositRate: action.paystackDepositRate ? action.paystackDepositRate : initialState.paystackDepositRate,
                paystackMinimumDeposit: action.paystackMinimumDeposit ? action.paystackMinimumDeposit : initialState.paystackMinimumDeposit,
                paystackTransactionLimit: action.paystackTransactionLimit
                    ? action.paystackTransactionLimit
                    : initialState.paystackTransactionLimit
            };

        default:
            return state;
    }
};

export default settingsReducer;
